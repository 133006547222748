import classNames from 'classnames';
import { Heading, HeadingStyle, Picture, RichText } from 'Shared';
import { toKebabCase } from 'Shared/utilities';
import styles from './HeroCard.module.scss';

export interface HeroCardProps {
	className?: string;
	teaser?: string;
	heading?: string;
	headingStyle?: HeadingStyle;
	image?: Image;
	kicker?: string;
	settings?: {
		properties?: {
			lightText?: boolean;
			style?: 'minimal' | 'simple' | 'blocked' | 'offset';
			backgroundColor?: string;
		};
	};
}

export const HeroCard: React.FC<HeroCardProps> = ({
	className,
	teaser,
	heading,
	headingStyle,
	image,
	kicker,
	settings,
}) => {
	const { lightText, style = 'blocked', backgroundColor } = settings?.properties ?? {};

	return (
		<header
			className={classNames(
				styles.HeroCard,
				styles[`HeroCard___${style}`],
				lightText && styles.HeroCard___lightText,
				className,
			)}
		>
			{image && (
				<div className={styles.HeroCard_imageContainer}>
					<Picture
						url={image?.url}
						properties={image?.properties}
						sizes="100vw"
						className={styles.HeroCard_mediabox}
						focalPoint={image?.focalPoint}
					/>
				</div>
			)}
			<div
				className={classNames(styles.HeroCard_textbox, {
					[`u-background-color--${toKebabCase(backgroundColor)}`]: backgroundColor,
				})}
			>
				{kicker && style !== 'simple' && <div className={styles.HeroCard_kicker}>{kicker}</div>}
				<Heading
					headingLevel="h1"
					style={headingStyle ?? '2xl'}
					className={classNames(styles.HeroCard_heading)}
				>
					{heading}
				</Heading>
				{teaser && style !== 'simple' && (
					<RichText className={styles.HeroCard_teaser} style="large" content={teaser} />
				)}
			</div>
		</header>
	);
};
