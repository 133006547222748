import classNames from 'classnames';

export interface ThemeContext {
	themeName: string;
	documentType: string;
	children?: React.ReactNode;
}

export const ThemeContext: React.FC<ThemeContext> = (props) => {
	return (
		<div className={classNames(`${props?.themeName}-theme`, `${props?.themeName}-theme--${props?.documentType}`)}>
			{props?.children}
		</div>
	);
};
