import classNames from 'classnames';
import { Heading, Link, Picture, RichText } from 'Shared';
import styles from './ContentTeaser.module.scss';

export interface ContentTeaserProps {
	className?: string;
	content?: string;
	image: Image;
	imageSizes?: string;
	imagePosition?: 'left' | 'right';
	link?: Link;
	title: string;
	teaserTheme: 'white' | 'theme';
}

export const ContentTeaser: React.FC<ContentTeaserProps> = ({
	className,
	image,
	imagePosition = 'left',
	imageSizes,
	title,
	content,
	link,
	teaserTheme,
}) => {
	return (
		<div className={classNames(styles.ContentTeaser, styles[`ContentTeaser___${imagePosition}`], className)}>
			<Picture className={styles.ContentTeaser_image} sizes={imageSizes} {...image} />
			<div
				className={classNames(
					styles.ContentTeaser_container,
					styles[`ContentTeaser_container___${imagePosition}`],
					styles[`ContentTeaser_container___${teaserTheme}`],
					className,
				)}
			>
				<Heading className={styles.ContentTeaser_title} headingLevel="h2">
					{title}
				</Heading>

				{content && <RichText className={styles.ContentTeaser_body} content={content} />}

				{link && (
					<Link url={link.url} style="dash">
						{link.name}
					</Link>
				)}
			</div>
		</div>
	);
};
