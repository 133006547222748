import React from 'react';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './Slider.module.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export interface SliderProps {
	className?: string;
	slides: Array<unknown>;
	initialSlide?: number;
	handleSlideChange?: (number) => void;
	settings?: {
		properties: {
			desktopWidth: GridCellWidth;
			mobileWidth: GridCellWidth;
		};
	};
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const Slider: React.FC<SliderProps> = ({ slides, initialSlide = 0, handleSlideChange = () => {} }) => {
	return (
		<Swiper
			initialSlide={initialSlide}
			speed={800}
			spaceBetween={0}
			slidesPerView={1}
			loop={true}
			centeredSlides={true}
			navigation
			pagination={{
				clickable: true,
				type: 'bullets',
			}}
			className={styles.Slider}
			onActiveIndexChange={(index) => {
				handleSlideChange(index.realIndex);
			}}
		>
			{slides.map((slide, i) => {
				return (
					<SwiperSlide key={i} className={styles.Slider_slide}>
						<div className={styles.Slider_slideItem}>{slide}</div>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};
