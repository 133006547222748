import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';

export interface SiteAreaState {
	currentSiteAreaName: string;
	siteAreas: Link[];
}

const initialState: SiteAreaState = {
	currentSiteAreaName: null,
	siteAreas: null,
};

export const siteAreaSlice = createSlice({
	name: 'siteArea',
	initialState,
	reducers: {
		setSiteAreas: (state, action: PayloadAction<Link[]>) => {
			state.siteAreas = action.payload;
		},
		setCurrentSiteArea: (state, action: PayloadAction<string>) => {
			state.currentSiteAreaName = action.payload;
		},
		clearSiteAreas: (state) => {
			state.currentSiteAreaName = null;
			state.siteAreas = null;
		},
	},
});

export const { setCurrentSiteArea, setSiteAreas, clearSiteAreas } = siteAreaSlice.actions;

export const selectCurrentSiteAreaName = (state: RootState): string => state.siteArea.currentSiteAreaName;
export const selectSiteAreas = (state: RootState): Link[] => state.siteArea.siteAreas;
