import classNames from 'classnames';
import { DictionaryContext } from 'Online/context/Dictionary';
import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'Shared';
import styles from './Video.module.scss';

export interface VideoProps {
	mediaURL?: string;
}

export const Video: React.FC<VideoProps> = ({ mediaURL }) => {
	const dictionary = useContext(DictionaryContext);
	const [vimeoId, setVimeoId] = useState('');

	const getVimeoIdFromUrl = (url) => {
		const match = /vimeo.*\/(\d+)/i.exec(url);

		if (match) {
			return match[1];
		}
	};

	useEffect(() => {
		setVimeoId(getVimeoIdFromUrl(mediaURL));
	}, [mediaURL]);

	return vimeoId ? (
		<Container>
			<div className={styles.Video}>
				<div className={styles.Video_responsiveWrapper}>
					<div className="cookieconsent-optin-marketing">
						<iframe
							className={styles.Video_iframe}
							data-cookieblock-src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0`}
							data-cookieconsent="marketing"
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							allowFullScreen
						></iframe>
						<script src="https://player.vimeo.com/api/player.js"></script>
					</div>

					<div className={classNames(styles.Video_cookieConsentWrapper, 'cookieconsent-optout-marketing')}>
						<p>
							<a href="javascript: Cookiebot.renew()">
								{dictionary.getValue(
									'Cookies.AcceptMarketingCookies',
									undefined,
									'Please accept marketing cookies to view this content.',
								)}
							</a>
						</p>
					</div>
				</div>
			</div>
		</Container>
	) : null;
};
