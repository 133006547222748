import classNames from 'classnames';
import styles from './ArticleMedia.module.scss';
import { Picture } from 'Shared';

export interface ArticleMediaProps {
	className?: string;
	icon?: string;
	image: Image;
	caption?: string;
}

export const ArticleMedia: React.FC<ArticleMediaProps> = ({ className, image, caption }) => {
	const renderImage = () => {
		return (
			<span className={styles.ArticleMedia_mediabox}>
				<Picture
					aspectRatio={0.4}
					focalPoint={image.focalPoint}
					url={image.url}
					properties={image.properties}
					sizes="100vw"
					className={styles.ArticleMedia_mediabox}
				/>
			</span>
		);
	};
	const renderFigure = () => {
		return (
			<figure>
				{renderImage()}
				<div>
					<figcaption className={styles.ArticleMedia_caption}>{caption}</figcaption>
				</div>
			</figure>
		);
	};
	return (
		<div className={classNames(styles.ArticleMedia, className)}>
			<div>{caption ? renderFigure() : renderImage()}</div>
		</div>
	);
};
