import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';

const initialState: PolicyDetailState = {
	areaContent: null,
	area: null,
	active: false,
};

export const policyDetailSlice = createSlice({
	name: 'policydetail',
	initialState,
	reducers: {
		setPolicyDetailModal: (state, action: PayloadAction<PolicyDetailState>) => {
			if (action.payload === null) {
				state.areaContent = null;
				state.area = null;
				state.active = false;
				return;
			}
			state.areaContent = action.payload.areaContent;
			state.area = action.payload.area;
			state.active = action.payload.active;
		},
	},
});

export const { setPolicyDetailModal } = policyDetailSlice.actions;

export const selectPolicyDetailState = (state: RootState): PolicyDetailState => {
	const policyDetailState = state.policyDetails;
	if (policyDetailState.active) return policyDetailState;
	return null;
};
