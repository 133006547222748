import classNames from 'classnames';
import { ErrorMessage, FormfieldHelpCta, Label } from 'Shared';
import styles from './FormfieldTypeheadDawa.module.scss';
import React, { useEffect, useState, useRef } from 'react';

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

type Address = {
	roadName: string;
	roadNumber: string;
	postalCode: string;
	city: string;
	houseLetter?: string;
	floor?: string;
	sideDoor?: string;
};

interface DawaAutocomplete {
	type: Type;
	tekst: string;
	forslagstekst: string;
	caretpos: number;
	data: Data;
}

interface Data {
	id: string;
	status: number;
	darstatus: number;
	vejkode: string;
	vejnavn: string;
	adresseringsvejnavn: string;
	husnr: string;
	etage: string;
	dør: string;
	supplerendebynavn: string;
	postnr: string;
	postnrnavn: string;
	stormodtagerpostnr: string;
	stormodtagerpostnrnavn: string;
	kommunekode: string;
	adgangsadresseid: string;
	x: number;
	y: number;
	href: string;
}

enum Type {
	Vejnavn = 'vejnavn',
}

interface Option {
	id: string;
	name: string;
	data?: Data;
}

export interface FormfieldTypeheadDawaProps {
	className?: string;
	closeCtaText?: string;
	defaultValue?: string;
	id: string;
	helpCtaText?: string;
	label: string;
	name?: string;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	errorMessage?: string;
	state?: State;
	getAddress: (data: Address) => void;
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, type: string, id: string) => {
	if (state?.['hasError']) return type + id;
};

export const FormfieldTypeheadDawa: React.FC<FormfieldTypeheadDawaProps> = ({
	className,
	closeCtaText,
	state,
	id,
	helpCtaText,
	label,
	register,
	placeholder,
	name,
	defaultValue = '',
	errorMessage,
	getAddress,
}) => {
	const inputRef = useRef(null);
	const { required, disabled, hasError, isHidden } = state ?? {};
	const [address, setAddress] = useState<Address>();
	const [value, setValue] = useState(defaultValue);
	const [options, setOptions] = React.useState<Option[]>([]);

	useEffect(() => {
		defaultValue.length && getDawaAutocomplete(defaultValue, true);
	}, []);

	useEffect(() => {
		getAddress(address);
	}, [address]);

	const getDawaAutocomplete = async (value: string, isCopiedText = false) => {
		const dawa = await fetch(`https://api.dataforsyningen.dk/autocomplete?q=${value}&type=adresse&fuzzy=`);
		const json = (await dawa.json()) as DawaAutocomplete[];

		if (isCopiedText) {
			const { postnr, vejnavn, husnr, postnrnavn } = json[0]?.data;
			setAddress({
				postalCode: postnr,
				roadName: vejnavn,
				roadNumber: husnr,
				city: postnrnavn,
			});
		} else {
			setOptions(
				json.map((item) => ({
					id: item.forslagstekst,
					name: item.forslagstekst,
					data: item.data,
				})),
			);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setValue(value);
		getDawaAutocomplete(value);
	};

	const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		if (options.length === 1) {
			getDawaAutocomplete(value, true).then(() => {
				optionSelected({ id: value, name: value });
			});
		}

		setTimeout(() => {
			setOptions([]);
		}, 200);
	};

	const optionSelected = (value: Option) => {
		if (value?.data) {
			const data = value?.data;

			'id' in data &&
				setAddress({
					city: data.postnrnavn,
					postalCode: data.postnr,
					roadName: data.vejnavn,
					roadNumber: data.husnr,
				});
		}

		setValue(value?.name);
		setOptions([]);
		inputRef.current.focus();
	};

	const renderOptions = () => {
		if (!options.length) return null;

		return (
			<ul className={classNames(styles.dawaAutocompleteSuggestions)}>
				{options.map((item, index) => {
					return (
						<li
							key={id + index}
							onClick={() => optionSelected(item)}
							className={classNames(styles.dawaAutocompleteSuggestions_item)}
						>
							{item.name}
						</li>
					);
				})}
			</ul>
		);
	};

	const { ref = null, ...rest } = register && {
		...register(name, {
			onBlur: (e) => handleBlur(e),
			onChange: (e) => handleChange(e),
			required: {
				value: required,
			},
		}),
	};

	return (
		<>
			<div
				className={classNames(
					styles.FormfieldString,
					className,
					{ [styles.hasError]: hasError },
					{ [styles.isDisabled]: disabled },
				)}
			>
				{!isHidden && (
					<div className={styles.FormfieldString_label}>
						<Label id={id} className={styles.FormfieldString_labelText} state={state}>
							{label}
						</Label>
						{helpCtaText && <FormfieldHelpCta closeText={closeCtaText} helpText={helpCtaText} />}
					</div>
				)}

				<input
					type="text"
					className={classNames(styles.FormfieldString_input, styles.Input___string, {
						[styles.hasError]: hasError,
					})}
					name={name}
					id={id}
					disabled={disabled}
					placeholder={placeholder}
					aria-label={ariaLabel(state, label)}
					aria-describedby={ariaError(state, 'text', id)}
					value={value}
					{...rest}
					ref={(e) => {
						ref(e);
						inputRef.current = e;
					}}
				/>
				{hasError && <ErrorMessage id={'text' + id}>{errorMessage}</ErrorMessage>}
				{renderOptions()}
			</div>
		</>
	);
};
