import styles from './Preview.module.scss';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { DictionaryContext } from 'Online/context/Dictionary';

export interface PreviewProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	content: any;
	children: React.ReactNode;
}

export const Preview: React.FC<PreviewProps> = ({ children, content }) => {
	const { isPreview } = useRouter();
	const dictionary = useContext(DictionaryContext);

	if (!isPreview) return <>{children}</>;

	return (
		<div className={styles.Preview}>
			<div className={styles.Preview_top}>
				<p>
					{dictionary.getValue('Preview.InPreview')}{' '}
					<a className={styles.Preview_link} href={`/api/exitPreview?slug=${content.page.url}`}>
						{dictionary.getValue('Preview.ExitPreview')}
					</a>
				</p>
			</div>
			<div className={styles.Preview_content}>{children}</div>
		</div>
	);
};
