import classNames from 'classnames';
import { useState } from 'react';
import { Modal } from 'Shared';
import { ReactComponent as QuestionIcon } from 'Shared/public/icons/icon-question.svg';
import styles from './FormfieldHelpCta.module.scss';

export interface FormfieldHelpCtaProps {
	closeText: string;
	helpText: string;
	className?: string;
}

export const FormfieldHelpCta: React.FC<FormfieldHelpCtaProps> = ({ closeText, helpText, className }) => {
	const [infoModuleOpen, setInfoModuleOpen] = useState(false);
	return (
		<div className={classNames(styles.FormfieldHelpCta, className)}>
			<QuestionIcon className={styles.FormfieldHelpCta_icon} onClick={() => setInfoModuleOpen(true)} />
			{infoModuleOpen && (
				<Modal handleClose={() => setInfoModuleOpen(false)} style="simple" closeText={closeText}>
					<p className={styles.FormfieldHelpCta_modalText}>{helpText}</p>
				</Modal>
			)}
		</div>
	);
};
