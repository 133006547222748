import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useEffect } from 'react';
import { Button, Heading, Image } from 'Shared';
import { ReactComponent as IconCloseCross } from 'Shared/public/icons/icon-cross.svg';
import styles from './Modal.module.scss';

export interface ModalProps {
	ariaClose?: string;
	closeText: string;
	children: React.ReactNode;
	className?: string;
	handleClose: () => void;
	icon?: Image;
	iconSVG?: React.ReactNode;
	title?: string;
	titleImage?: Image;
	role?: 'dialog' | 'alert';
	subTitle?: string;
	style?: 'withoutForm' | 'confirmation' | 'simple';
	size?: 'small';
}

export const Modal: React.FC<ModalProps> = ({
	ariaClose = 'luk',
	closeText = 'Annuler og luk',
	children,
	className,
	handleClose,
	icon,
	iconSVG,
	title,
	titleImage,
	role = 'dialog',
	subTitle,
	style,
	size,
}) => {
	const closeEscape = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			handleClose();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', closeEscape);

		return () => {
			document.removeEventListener('keydown', closeEscape);
		};
	});

	return (
		<FocusTrap>
			<div
				className={classNames(styles.Modal, styles[`Modal___${style}`], className)}
				role={role}
				aria-modal={true}
				aria-describedby="dialog-description"
			>
				<div className={classNames(styles.Modal_overlay, 'u-center-absolute')} onClick={handleClose}></div>
				<div className={classNames(styles.Modal_dialog, styles[`Modal_dialog___${size}`])}>
					<button className={styles.Modal_close} onClick={handleClose} aria-label={ariaClose}>
						<IconCloseCross />
					</button>
					{icon && (
						<div className={styles.Modal_icon}>
							<Image url={icon.url} sizes="14rem" aria-hidden="true" properties={icon.properties} />
						</div>
					)}

					{iconSVG && <div className={styles.Modal_icon}>{iconSVG}</div>}
					{title && (
						<div className={styles.Modal_titleContainer}>
							<div className={styles.Modal_titleContentContainer}>
								<Heading headingLevel="h2" style="md" id="dialog-description">
									{title}
								</Heading>
								{subTitle && (
									<Heading headingLevel="h3" style="xs" className={styles.Modal_subTitle}>
										{subTitle}
									</Heading>
								)}
							</div>
							{titleImage && (
								<Image
									properties={titleImage.properties}
									url={titleImage.url}
									sizes={'3rem'}
									className={styles.Modal_titleImage}
								/>
							)}
						</div>
					)}
					<div className={styles.Modal_contentContainer}>{children}</div>

					<Button
						className={styles.Modal_controlsCancel}
						style={
							style === 'withoutForm' || style == 'simple' || style === 'confirmation'
								? 'primary'
								: 'link'
						}
						onClick={handleClose}
					>
						{closeText}
					</Button>
				</div>
			</div>
		</FocusTrap>
	);
};
