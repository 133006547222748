import { combineReducers } from '@reduxjs/toolkit';
import { DESTROY_SESSION } from 'Online/store/actionTypeConstants';
import { authenticateSlice, AuthenticateState } from './slices/authenticateSlice';
import { cartSlice, CartState } from './slices/cartSlice';
import { documentsSlice, documentsState } from './slices/documentsSlice';
import { mastercardSlice, MastercardState } from './slices/mastercardSlice';
import { partySlice, PartyState } from './slices/partySlice';
import { policyDetailSlice } from './slices/policyDetailSlice';
import { policiesSlice, PoliciesState } from './slices/policySlice';
import { policyTypeContentSlice, PolicyTypeContentState } from './slices/policyTypeContentSlice';
import { siteAreaSlice, SiteAreaState } from './slices/siteAreaSlice';
import { upsellCoverageSlice, UpsellCoverageState } from './slices/upsellCoverageSlice';

export interface State {
	authenticate: AuthenticateState;
	cart: CartState;
	mastercard: MastercardState;
	party: PartyState;
	documents: documentsState;
	policies: PoliciesState;
	policyDetails: PolicyDetailState;
	policyTypeContent: PolicyTypeContentState;
	upsellCoverages: UpsellCoverageState;
	siteArea: SiteAreaState;
}
// Combine all reducers.
const appReducer = combineReducers({
	authenticate: authenticateSlice.reducer,
	cart: cartSlice.reducer,
	mastercard: mastercardSlice.reducer,
	party: partySlice.reducer,
	documents: documentsSlice.reducer,
	policies: policiesSlice.reducer,
	policyDetails: policyDetailSlice.reducer,
	policyTypeContent: policyTypeContentSlice.reducer,
	upsellCoverages: upsellCoverageSlice.reducer,
	siteArea: siteAreaSlice.reducer,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = (state: State, action) => {
	// Clear all data in redux store to initial.
	if (action.type === DESTROY_SESSION) state = undefined;

	return appReducer(state, action);
};
export default rootReducer;
