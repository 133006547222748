import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasterCardTravelFormData, MasterCardUserFormData } from 'Online/components';
import { RootState } from 'Online/store/store';
import { getApiInstance } from 'Online/utilities/getApiInstance';
import { getPartnerByCardType } from 'Online/utilities/getPartnerByCadType';

export interface MastercardState {
	config: {
		data: MasterCard;
		status: Status;
	};
	userFormData: MasterCardUserFormData;
	travelFormData: MasterCardTravelFormData;
	priceSummary: {
		data: PriceSummary;
		status: Status;
	};
	paymentId: string;
}

const initialState: MastercardState = {
	config: {
		data: {} as MasterCard,
		status: 'pending',
	},
	userFormData: null,
	travelFormData: null,
	priceSummary: {
		data: null,
		status: null,
	},
	paymentId: null,
};

const api = getApiInstance();
export const getMasterCardConfig = createAsyncThunk(
	'MasterCard/Config',
	async (masterCardType: MasterCardType, { rejectWithValue }) => {
		try {
			const partner = getPartnerByCardType(masterCardType);
			const config = await api.get(`/api/v1/travelsales/GetCardConfigByPartner/${partner}`);
			return await config.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getMasterCardPrice = createAsyncThunk(
	'MasterCard/Price',
	async (
		{
			travelFormData,
			masterCardType,
		}: {
			travelFormData: MasterCardTravelFormData;
			masterCardType: MasterCardType;
		},
		thunkAPI,
	) => {
		const state = thunkAPI.getState() as RootState;
		const partner = getPartnerByCardType(masterCardType);
		const cardInfo = state?.mastercard?.config.data.cardInfo.find((card) => card?.name === masterCardType);
		let price: string | number;
		if (cardInfo?.name === 'WEMN') {
			price = travelFormData.price;
		} else {
			price =
				Number(travelFormData.price) > cardInfo?.publicCancellationCoverage?.maxSum
					? cardInfo?.publicCancellationCoverage?.maxSum
					: travelFormData.price;
		}
		const priceTravelCompensation =
			Number(travelFormData.priceTravelCompensation) > cardInfo?.publicTravelCompensationCoverage?.maxSum &&
			cardInfo?.publicTravelCompensationCoverage?.maxSum !== null
				? cardInfo?.publicTravelCompensationCoverage?.maxSum
				: travelFormData.priceTravelCompensation;
		try {
			const priceResonse = await api.post(
				`/api/v1/travelsales/CalculatePrice/${partner}/${masterCardType}`,
				JSON.stringify({
					numberOfInsured: travelFormData.insuredCount,
					startDate: travelFormData.startDate,
					endDate: travelFormData.endDate,
					extendedTravelCoverageSelected:
						travelFormData.extendedTravelCoverageSelected === undefined
							? true
							: travelFormData.extendedTravelCoverageSelected,
					isExtension: travelFormData.isExtension,
					price: price ? price : '0',
					priceTravelCompensation: priceTravelCompensation ? priceTravelCompensation : '0',
				} as MasterCardTravelFormData),
			);
			const priceCalculationResponse: PriceSummary & ErrorResponse = await priceResonse.data;
			if (priceCalculationResponse.errorCode) return thunkAPI.rejectWithValue(priceCalculationResponse.errorCode);
			return priceCalculationResponse as PriceSummary;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const mastercardSlice = createSlice({
	name: 'Mastercard',
	initialState,
	reducers: {
		setUserFormData: (state, action: PayloadAction<MasterCardUserFormData>) => {
			state.userFormData = action.payload;
		},
		setTravelFormData: (state, action: PayloadAction<MasterCardTravelFormData>) => {
			state.travelFormData = action.payload;
		},
		setPaymentId: (state, action: PayloadAction<string>) => {
			state.paymentId = action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(getMasterCardConfig.fulfilled, (state, action) => {
				state.config.data = action.payload;
				state.config.status = 'success';
			})
			.addCase(getMasterCardConfig.pending, (state) => {
				state.config.status = 'pending';
			})
			.addCase(getMasterCardConfig.rejected, (state) => {
				state.config.status = 'error';
			})

			.addCase(getMasterCardPrice.fulfilled, (state, action) => {
				state.priceSummary.status = 'success';
				state.priceSummary.data = action.payload;
			})
			.addCase(getMasterCardPrice.pending, (state) => {
				state.priceSummary.status = 'pending';
			})
			.addCase(getMasterCardPrice.rejected, (state) => {
				state.priceSummary.status = 'error';
			});
	},
});

export const { setUserFormData, setTravelFormData, setPaymentId } = mastercardSlice.actions;

export const selectUserFormData = (state: RootState): MasterCardUserFormData => {
	return state.mastercard.userFormData;
};
export const selectPriceSummary = (state: RootState): PriceSummary => {
	return state.mastercard.priceSummary.data;
};
export const selectTravelFormData = (state: RootState): MasterCardTravelFormData => {
	return state.mastercard.travelFormData;
};

export const selectMasterCardConfig = (state: RootState, cardName: string): MasterCardConfig => {
	return state.mastercard.config.data
		? state.mastercard.config.data.cardInfo?.find((card) => card.name === cardName)
		: null;
};
export const selectMasterCardPaymentId = (state: RootState): string => {
	return state.mastercard.paymentId;
};

export const selectMasterCardConfigStatus = (state: RootState): Status => {
	return state.mastercard.config.status;
};
export const selectMasterCardCalculationStatus = (state: RootState): Status => {
	return state.mastercard.priceSummary.status;
};
