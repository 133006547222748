import { BlurbCard, BlurbCardProps, Grid, GridCell, Link } from 'Shared';

export interface ListProps {
	items: BlurbCardProps[];
	desktopWidth?: GridCellWidth;
	mobileWidth?: GridCellWidth;
	link?: Link;
}

export const List: React.FC<ListProps> = ({ items, desktopWidth = '100', mobileWidth = '100', link }) => {
	if (!items) return null;
	let gridLink: JSX.Element;
	if (link) {
		gridLink = (
			<GridCell desktopWidth={desktopWidth} mobileWidth={mobileWidth} center={true}>
				<div className="u-text-center u-top-margin--xl">{link && <Link url={link.url}>{link.name}</Link>}</div>
			</GridCell>
		);
	}

	const grid = (
		<Grid wrap={true}>
			{items.map((args, index) => (
				<BlurbCard key={index} {...args} />
			))}
			{gridLink}
		</Grid>
	);

	return grid;
};
