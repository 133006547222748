import classNames from 'classnames';
import styles from './FormfieldRadio.module.scss';
import { Label } from 'Shared';

export interface FormfieldRadioProps {
	className?: string;
	defaultChecked?: boolean;
	value?: string;
	style?: 'default' | 'circle' | 'star' | 'button';
	id: string;
	label: string;
	name: string;
	fieldGroup?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	state?: {
		hasError?: boolean;
		disabled?: boolean;
		required?: boolean;
	};
	onChange?: (value: string) => void;
}

export const FormfieldRadio: React.FC<FormfieldRadioProps> = ({
	className,
	value,
	defaultChecked,
	state,
	id,
	label,
	name,
	register,
	fieldGroup,
	style = 'default',
	onChange,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	return (
		<div
			className={classNames(
				styles.FormfieldRadio,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				styles[`FormfieldRadio___${style}`],
			)}
		>
			<input
				type="radio"
				className={classNames(styles.FormfieldRadio_input, styles.Input___choice)}
				name={name}
				id={id}
				defaultChecked={defaultChecked}
				disabled={disabled}
				value={value}
				required={required}
				{...(register && { ...register(name, { required }) })}
				{...(onChange && onChange(value))}
			/>
			<Label id={id} className={styles.FormfieldRadio_label} state={labelState}>
				{label}
			</Label>
		</div>
	);
};
