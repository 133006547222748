import { DictionaryContext } from 'Online/context/Dictionary';
import { _Actions, _Notices } from 'Online/dictionaryItems';
import { PageContext } from 'Online/context/PageContext';
import { ReactComponent as IdleIconPsk } from 'Online/public/icons/icon-idle-psk.svg';
import { ReactComponent as IdleIconCodan } from 'Online/public/icons/icon-idle-codan.svg';
import { selectUnloadTime, setUnloadTime } from 'Online/store/slices/authenticateSlice';
import { selectParty } from 'Online/store/slices/partySlice';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'Shared';
import modalStyle from 'Shared/components/3-organisms/Modal/Modal.module.scss';
import { getCookieByName } from 'Shared/utilities/getCookieByName';
import { getApiInstance } from 'utilities/getApiInstance';

export interface IdleLogOut {
	children: React.ReactNode;
	isExpiredCallback: () => void;
}

export const IdleLogOut: React.FC<IdleLogOut> = ({ children, isExpiredCallback }) => {
	const dispatch = useDispatch();
	const dictionary = useContext(DictionaryContext);
	const pageContext = useContext(PageContext);
	const themeName = pageContext.theme;

	const unloadTime = useSelector(selectUnloadTime);
	const [isInactive, setIsInactive] = useState(false);

	const partyData = useSelector(selectParty);

	useEffect(() => {
		const interval = setInterval(() => {
			const expirationDate = Number(getCookieByName('X-Expiration-Date'));
			if (expirationDate < Date.now() || !expirationDate) return isExpiredCallback();
			if (expirationDate < Date.now() + 60000 * 5) return setIsInactive(true);
			else setIsInactive(false);
		}, 5000);
		return () => clearInterval(interval);
	}, [isExpiredCallback, isInactive]);

	useEffect(() => {
		if (unloadTime && Date.now() >= unloadTime) {
			isExpiredCallback();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unloadTime]);

	window.onbeforeunload = () => {
		const expiryTimeOnUnload = Date.now() + 60000 * 5;
		dispatch(setUnloadTime(expiryTimeOnUnload));
		const disablePopup = undefined;
		return disablePopup;
	};

	const handleRefresh = async () => {
		const newToken = await getApiInstance('', true).get('/api/v1/Meta/Refresh');
		return newToken.status == 200 ? setIsInactive(false) : isExpiredCallback();
	};

	return (
		<>
			{children}
			{isInactive && !(partyData?.status === 'error') && (
				<Modal
					title={_Notices(dictionary, 'InactiveTitle')}
					closeText={_Actions(dictionary, 'Continue')}
					iconSVG={
						<div className={modalStyle.Modal_IdleIcon}>
							{themeName == 'Privatsikring' ? (
								<IdleIconPsk className={modalStyle.Modal_IdleSvg} />
							) : (
								<IdleIconCodan className={modalStyle.Modal_IdleSvg} />
							)}
						</div>
					}
					style={'confirmation'}
					handleClose={handleRefresh}
				>
					<p className="u-text-center">{_Notices(dictionary, 'Inactive5minWarning')}</p>
				</Modal>
			)}
		</>
	);
};
