import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';

export interface PolicyTypeContentState {
	policyTypeItems: PolicyTypeItem[];
}

const initialState: PolicyTypeContentState = {
	policyTypeItems: null,
};

export const policyTypeContentSlice = createSlice({
	name: 'policy type content',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		updatePolicyTypeContent: (state, action: PayloadAction<{ [key: string]: any }>) => {
			const policyTypeContent = action.payload as PolicyTypeItem[];
			state.policyTypeItems = policyTypeContent;
		},
	},
});

export const { updatePolicyTypeContent } = policyTypeContentSlice.actions;

export const selectPolicyTypeContent = (state: RootState): PolicyTypeItem[] => state.policyTypeContent.policyTypeItems;
