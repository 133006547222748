import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';

export interface CartState {
	cartProducts: CartProduct[];
	totalSum: number;
	showCart: boolean;
}

const initialState: CartState = {
	cartProducts: [],
	totalSum: 0,
	showCart: false,
};

const getVariantById = (state: CartState, variantId: string) => {
	return state.cartProducts
		.map((product) => {
			return product.variantId;
		})
		.indexOf(variantId);
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		addToCart: (state, action: PayloadAction<{ [key: string]: any }>) => {
			const product = action.payload as CartProduct;
			const productIndexInCart = getVariantById(state, product.variantId);
			if (productIndexInCart === -1) state.cartProducts.push(product);
			if (productIndexInCart != -1) state.cartProducts[productIndexInCart].quantity += 1;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		removeFromCart: (state, action: PayloadAction<{ [key: string]: any }>) => {
			const cartProduct = action.payload as CartProduct;
			const variantId = cartProduct.variantId as string;
			const productIndex = getVariantById(state, variantId);
			state.cartProducts.splice(productIndex, 1);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		decrementCartProductQuantity: (state, action: PayloadAction<{ [key: string]: any }>) => {
			const cartProduct = action.payload as CartProduct;
			const productIndex = getVariantById(state, cartProduct.variantId);
			if (cartProduct.quantity <= 1) cartSlice.caseReducers.removeFromCart(state, action);
			if (cartProduct.quantity > 1) state.cartProducts[productIndex].quantity -= 1;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		toggleCartVisiblity: (state, action: PayloadAction<boolean>) => {
			state.showCart = action.payload;
		},
	},
});

export const { addToCart, removeFromCart, decrementCartProductQuantity, toggleCartVisiblity } = cartSlice.actions;

export const selectCartProducts = (state: RootState): CartProduct[] => state.cart.cartProducts;
export const selectCartVisibility = (state: RootState): boolean => state.cart.showCart;
