import styles from './Pagination.module.scss';
import { Icon } from 'Shared';
import { ReactComponent as ChevronLeft } from 'Shared/public/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from 'Shared/public/icons/icon-chevron-right.svg';
import classNames from 'classnames';

export interface PaginationProps {
	controlFirst?: PaginationControlProps;
	controlPrev?: PaginationControlProps;
	controlNext?: PaginationControlProps;
	controlLast?: PaginationControlProps;
	paginationItems?: Array<PaginationItemProps>;
}

export interface PaginationItemProps {
	label?: string;
	link?: string;
	active?: boolean;
}

export interface PaginationControlProps {
	direction?: 'next' | 'prev';
	label?: string;
	link?: string;
	title?: string;
	ariaLabel?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
	controlFirst,
	controlPrev,
	controlNext,
	controlLast,
	paginationItems,
}) => (
	<ol className={styles.Pagination}>
		{controlFirst && (
			<PaginationControl {...controlFirst}>
				<Icon>
					<ChevronLeft />
				</Icon>
			</PaginationControl>
		)}

		{controlPrev && (
			<PaginationControl {...controlPrev}>
				<Icon>
					<ChevronLeft />
				</Icon>
			</PaginationControl>
		)}

		{paginationItems.map((paginationItem: PaginationItemProps, index) => {
			const label = paginationItem.label || (index + 1).toString();

			return (
				<li key={index} className={styles.Pagination_item}>
					<a className={styles.Pagination_link} href={paginationItem.link} title={label}>
						{label}
					</a>
				</li>
			);
		})}

		{controlNext && (
			<PaginationControl {...controlNext}>
				<Icon>
					<ChevronRight />
				</Icon>
			</PaginationControl>
		)}

		{controlLast && (
			<PaginationControl {...controlLast}>
				<Icon>
					<ChevronRight />
				</Icon>
			</PaginationControl>
		)}
	</ol>
);

const PaginationControl: React.FC<PaginationControlProps> = ({
	children,
	direction,
	label = '',
	link,
	title,
	ariaLabel,
}) => {
	const hasLabelStyle =
		label.length > 0
			? classNames(styles.Pagination_item, styles.Pagination_item___gutter, styles.Pagination_item___mobile)
			: styles.Pagination_item;

	return (
		<li className={hasLabelStyle}>
			<a
				className={classNames(styles.Pagination_link, styles.Pagination_link___next)}
				href={link}
				title={title}
				aria-label={ariaLabel}
			>
				{direction === 'prev' && label}
				<span className={styles.Pagination_chevron}>{children}</span>
				{direction === 'next' && label}
			</a>
		</li>
	);
};
