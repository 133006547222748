import classNames from 'classnames';
import { Heading } from 'Shared';
import { toKebabCase } from 'Shared/utilities';
import styles from './DescriptionList.module.scss';
import { DescriptionListItem, DescriptionListItemProps } from './DescriptionListItem';

export interface DescriptionListProps {
	className?: string;
	heading: string;
	descriptionList: DescriptionListItemProps[];
}

export const DescriptionList: React.FC<DescriptionListProps> = ({ className, heading, descriptionList }) => {
	const ariaId = toKebabCase(heading);
	return (
		<div className={classNames(styles.DescriptionList, className)}>
			<Heading className={styles.DescriptionList_heading} headingLevel="h3" id={ariaId}>
				{heading}
			</Heading>
			<dl aria-describedby={ariaId}>
				{descriptionList.map((item, index) => {
					return <DescriptionListItem className={styles.DescriptionList_item} key={index} {...item} />;
				})}
			</dl>
		</div>
	);
};
