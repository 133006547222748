import classNames from 'classnames';
import React, { useState } from 'react';
import { Container, Heading, RichText } from 'Shared';
import styles from './Accordion.module.scss';
import { AccordionItem, AccordionItemProps } from './AccordionItem';

export interface AccordionProps {
	allowMultiple?: boolean;
	accordionItems: AccordionItemProps[];
	className?: string;
	title?: string;
	description?: string;
	settings: {
		properties: {
			containerWidth: ContainerWidth;
		};
	};
}

export const Accordion: React.FC<AccordionProps> = ({ accordionItems, className, description, title, settings }) => {
	const [openId, setOpenId] = useState(null);

	const handleToggling = (id: string, state: boolean) => {
		if (openId === id) return setOpenId(false);
		if (state) return setOpenId(false);
		setOpenId(id);
	};

	const handleIsOpen = (id: string) => {
		if (openId === null) return null;
		return openId === id ? true : false;
	};

	return (
		<div className={classNames(styles.Accordion, className)}>
			<Container width={settings?.properties?.containerWidth}>
				{title && (
					<Heading className={styles.Accordion_heading} headingLevel="h2" style="sm">
						{title}
					</Heading>
				)}
				{description && <RichText className={styles.Accordion_description} content={description} />}
				<div className={styles.Accordion_items}>
					{accordionItems.map((accordionItem, index) => {
						const id = `accordion${accordionItem.content.properties.title[0]}-${index}`;
						return (
							<AccordionItem
								{...accordionItem}
								key={id}
								id={id}
								className={styles.Accordion_trigger}
								isOpen={handleIsOpen(id)}
								toggle={(id, state) => handleToggling(id, state)}
							/>
						);
					})}
				</div>
			</Container>
		</div>
	);
};
