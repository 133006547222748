import { ComponentProps } from 'Online/components/Component';
import React from 'react';
import { PageProps } from './PageContext';
export interface NavigationContent {
	umbracoNaviHide: boolean;
	navigationTitle: string;
	reportIncidentLink?: Link;
	userlink?: boolean;
}

export interface HeaderItemProps {
	name: string;
	url: string;
	id: number;
	documentType: string;
	properties: {
		pageSettings: ComponentProps<NavigationContent>[];
		navigationLink?: Link[];
	};
	children?: {
		name: string;
		url: string;
		id: number;
		documentType: string;
		properties: {
			pageSettings: ComponentProps<NavigationContent>[];
			navigationLink: Link[];
		};
	}[];
}

export type SiteRootProps = {
	header: HeaderItemProps[];
};

const getRelativeHeader = (header, siteArea) => {
	const siteAreaPage = header?.find((item) => {
		if (item.name === siteArea) return item;
	});
	if (siteAreaPage?.children) return siteAreaPage.children;
	return header;
};
const getNavigationSettings = (pageItem) => {
	return pageItem?.properties?.pageSettings?.find((e) => e.documentType == 'navigation')?.content?.properties;
};
const getNavigationLink = (pageItem) => {
	return pageItem?.properties?.navigationLink;
};
export class Site {
	private _culture: string;
	private _siteSettings?: ComponentProps[];
	private _root: SiteRootProps;

	public constructor(root: SiteRootProps, culture: string, siteSettings?: ComponentProps[]) {
		this._culture = culture;
		this._root = root;
		this._siteSettings = siteSettings;
	}

	get culture(): string {
		return this._culture;
	}

	get siteSettings(): ComponentProps[] {
		return this._siteSettings;
	}

	get root(): SiteRootProps {
		return this._root;
	}

	public getSettings<T>(key: string): T {
		if (!this.siteSettings) return null;
		return this.siteSettings.find((e) => e.documentType === key)?.content?.properties;
	}

	public getUserNavigationItems(
		pageItem: {
			documentType?: string;
			id: number;
			name?: string;
			template?: string;
			url?: string;
			properties?: PageProps;
		},
		header: HeaderItemProps[],
	): HeaderItemProps[] {
		const userLinks = header?.reduce((filtered, headerItem) => {
			const navigationSettings = getNavigationSettings(headerItem);
			if (navigationSettings?.userlink) {
				filtered.push({
					title: navigationSettings?.navigationTitle ? navigationSettings?.navigationTitle : headerItem.name,
					url: headerItem?.url,
					state: pageItem?.id === headerItem?.id ? 'isActive' : '',
				});
			}
			return filtered;
		}, []);
		return userLinks;
	}

	public getNavigationItems(
		pageItem: {
			documentType?: string;
			id: number;
			name?: string;
			template?: string;
			url?: string;
			properties?: PageProps;
		},
		siteArea: string = null,
	): HeaderItemProps[] {
		const navSettings = getNavigationSettings(pageItem);
		if (navSettings?.overwriteNavigation.length > 0) {
			return navSettings.overwriteNavigation.map((link) => {
				return { title: link.name, url: link.url, state: '' };
			});
		}

		const header = this._root?.header;
		const relativeHeader = getRelativeHeader(header, siteArea);

		return relativeHeader?.reduce((filtered, headerItem) => {
			const navigationSettings = getNavigationSettings(headerItem);
			const navigationLink = getNavigationLink(headerItem);

			if (!navigationSettings?.umbracoNaviHide && !navigationSettings?.userlink && !navigationLink) {
				filtered.push({
					title: navigationSettings?.navigationTitle ? navigationSettings?.navigationTitle : headerItem.name,
					url: headerItem?.url,
					state: pageItem?.id === headerItem?.id ? 'isActive' : '',
				});
			}

			if (navigationLink) {
				filtered.push({
					title: navigationLink[0].name,
					url: navigationLink[0].url,
					state: '',
				});
			}

			return filtered;
		}, []);
	}
}

export const SiteContext = React.createContext<Partial<Site>>(new Site(null, 'en-US', []));
