import classNames from 'classnames';
import { Image, RichText } from 'Shared';
import styles from './FactboxItem.module.scss';

export interface FactboxItemProps {
	className?: string;
	content?: {
		properties?: {
			image?: Image;
			text?: string;
		};
	};
	style?: 'vanilla';
}

export const FactboxItem: React.FC<FactboxItemProps> = ({ className, content, style }) => {
	const { image, text } = content?.properties ?? {};
	return (
		<div className={classNames(styles.FactboxItem, styles[`FactboxItem___${style}`], className)}>
			<div className={styles.FactboxItem_imageContainer}>
				<Image
					isCircular={style === 'vanilla' ? false : true}
					isCover={style === 'vanilla' ? false : true}
					sizes="11rem"
					url={image.url}
					properties={image.properties}
					aspectRatio={1}
				/>
			</div>

			<RichText className={styles.FactboxItem_text} content={text} />
		</div>
	);
};
