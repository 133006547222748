export const getCookieByName = (cookieName: string): string => {
	const cookie = {};
	document.cookie.split(';').forEach(function (el) {
		const [key, value] = el.split('=');
		cookie[key.trim()] = value;
	});
	return cookie[cookieName];
};

export const getCookieByNameWithCookie = (cookieName: string, cookieInput: string): string => {
	const cookies = {};
	cookieInput.split(';').forEach(function (el) {
		const [key, value] = el.split('=');
		cookies[key.trim()] = value;
	});
	return cookies[cookieName];
};
