import { ComponentProps } from 'Online/components/Component';
import React from 'react';
import { HeaderItemProps } from './SiteContext';

export interface PageProps {
	pageSettings: ComponentProps[];
	children?: React.ReactNode;
}

interface UmbracoTheme {
	properties: {
		themeName: ThemeName;
	};
}
interface UmbracoAuthentication {
	properties: {
		authenticationRequired: boolean;
	};
}
export interface PageTypeProps<T = PageProps> {
	page: {
		documentType: string;
		id: number;
		name: string;
		template: string;
		url: string;
		properties: T;
	};
	root: {
		header: HeaderItemProps[];
		properties: {
			siteSettings: ComponentProps[];
		};
	};
	rootPage: {
		name: string;
		url: string;
		id: number;
		documentType: string;
		template: string;
		properties: T;
	};
	theme?: UmbracoTheme[];
	authentication: UmbracoAuthentication[];
	footer?: {
		pageFooter: ComponentProps[];
	};
}

export class Page {
	private _pageType: PageTypeProps;

	public constructor(pageType: PageTypeProps) {
		this._pageType = pageType;
	}

	public get template(): string {
		return this._pageType?.page?.template;
	}

	public get name(): string {
		return this._pageType?.page?.name;
	}

	public get settings(): ComponentProps[] {
		return this.pageType?.page?.properties?.pageSettings;
	}

	public get pageType(): PageTypeProps {
		return this._pageType;
	}

	public get id(): number {
		return this._pageType?.page?.id;
	}

	public get url(): string {
		return this._pageType?.page?.url;
	}

	public get content(): PageProps {
		return this.pageType?.page?.properties;
	}

	public get footer(): ComponentProps[] {
		return this.pageType?.footer?.pageFooter;
	}

	public get theme(): ThemeName {
		return this.pageType?.theme?.[0]?.properties?.themeName;
	}
	public get authentication(): boolean {
		return this.pageType?.authentication?.[0]?.properties?.authenticationRequired;
	}

	public getSettings<T>(key: string): T {
		return this.settings?.find((e) => e.documentType === key)?.content?.properties;
	}
}

export const PageContext = React.createContext<Partial<Page>>(new Page(null));
