import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';

export interface AuthenticateState {
	unloadTime: number;
}

const initialState: AuthenticateState = {
	unloadTime: null,
};

export const authenticateSlice = createSlice({
	name: 'Authentication Status',
	initialState,
	reducers: {
		setUnloadTime: (state, action: PayloadAction<number>) => {
			state.unloadTime = action.payload;
		},
	},
});

export const { setUnloadTime } = authenticateSlice.actions;

export const selectUnloadTime = (state: RootState): number => state.authenticate.unloadTime;
