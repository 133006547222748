import { useRef, useState } from 'react';
import { Container, PageLoader } from 'Shared';
import { debounce } from 'Shared/utilities';
import styles from './Iframe.module.scss';

export interface IframeProps {
	className?: string;
	iframeUrl: string;
	description: string;
	minHeight?: number;
	settings: {
		properties: {
			containerWidth: ContainerWidth;
		};
	};
}

export const Iframe: React.FC<IframeProps> = ({ iframeUrl, description, minHeight, settings }) => {
	const [iFrameHeight, setIframeHeight] = useState(minHeight ? minHeight : null);
	const [iFrameLoaded, setIframeLoaded] = useState(false);

	const FRAME_HEIGHT = 'FrameHeight';
	const REDIRECT_TO = 'RedirectTo';
	const SCROLL_TO = 'ScrollTo';

	const ifrm = useRef();

	window.addEventListener(
		'resize',
		debounce(() => {
			postIframeHeight(ifrm);
		}),
	);

	window.addEventListener('message', function (event) {
		const { data } = event;
		if (Object.prototype.hasOwnProperty.call(data, FRAME_HEIGHT)) {
			setIframeHeight(event.data.FrameHeight);
		}
		if (Object.prototype.hasOwnProperty.call(data, REDIRECT_TO)) {
			setRedirectTo(event.data.RedirectTo);
		}
		if (Object.prototype.hasOwnProperty.call(data, SCROLL_TO)) {
			setScrollTo(event.data.ScrollTo);
		}
	});

	function postIframeHeight(ifrm) {
		if (ifrm != null && ifrm.contentWindow != null) {
			ifrm.contentWindow.postMessage(FRAME_HEIGHT, '*');
		}
	}

	function setRedirectTo(url) {
		window.location.replace(url);
	}

	function setScrollTo(element) {
		document.querySelector(element).scrollIntoView({
			behavior: 'smooth',
		});
	}

	const style = {
		height: iFrameHeight + 'px',
	};

	const iframeLoaded = () => {
		setIframeLoaded(true);
	};

	return (
		<div className={styles.Iframe}>
			<Container width={settings?.properties?.containerWidth}>
				{!iFrameLoaded && <PageLoader maxHeight="41rem" />}
				<iframe
					ref={ifrm}
					className={styles.Iframe_asset}
					src={iframeUrl}
					onLoad={iframeLoaded}
					title={description}
					width="100%"
					style={style}
				></iframe>
			</Container>
		</div>
	);
};
