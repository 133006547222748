import React from 'react';

export type Replacement = {
	name: string;
	value: string;
};

export class DictionaryItem {
	key: string;
	values: {
		culture: string;
		value: string;
	}[];
}

export class Dictionary {
	private _culture: string;
	private _items: DictionaryItem[];

	public constructor(items: DictionaryItem[], culture: string) {
		this._culture = culture;
		this._items = items;
	}

	get culture(): string {
		return this._culture;
	}
	set culture(value: string) {
		this._culture = value;
	}

	public getValue(key: string, replacements?: Replacement[], defaultValue?: string): string {
		const dictionaryValue: DictionaryItem = this._items.find(
			(element) => element?.key.toLocaleLowerCase() === key.toLocaleLowerCase(),
		);
		let dictionaryString = dictionaryValue?.values?.find(
			(element) => element?.culture.toLocaleLowerCase() === this._culture.toLocaleLowerCase(),
		)?.value;

		if (!dictionaryString) {
			if (!defaultValue) {
				//Cannot find dictionary text and no default supplied - in development we use a default error text
				return process.env.NODE_ENV === 'development'
					? `[DICTIONARY ${key} MISSING FOR ${this._culture}]`
					: null;
			} else {
				return (dictionaryString = defaultValue);
			}
		}

		if (!replacements) {
			return dictionaryString;
		}

		replacements.forEach((replacement) => {
			return (dictionaryString = dictionaryString.replace('${' + replacement.name + '}', replacement.value));
		});

		return dictionaryString;
	}
}

export const DictionaryContext = React.createContext<Partial<Dictionary>>(
	new Dictionary([], process.env.DEFAULT_LOCALE ?? 'en-US'),
);
