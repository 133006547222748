import classNames from 'classnames';
import styles from './SocialLinks.module.scss';
import { Link, LinkProps } from 'Shared';

export interface SocialLinksProps {
	className?: string;
	SocialLinksItems?: LinkProps[];
}

export const SocialLinks: React.FC<SocialLinksProps> = ({ className, SocialLinksItems }) => (
	<ul className={classNames(styles.SocialLinks, className)}>
		{SocialLinksItems.map((SocialLinksItem: LinkProps, index) => (
			<li key={index} className={styles.SocialLinks_item}>
				<Link className={styles.SocialLinks_link} {...SocialLinksItem} />
			</li>
		))}
	</ul>
);
