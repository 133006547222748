import classNames from 'classnames';
import { Icon, Image, RichText } from 'Shared';
import styles from './AttentionCard.module.scss';

interface AttentionCardSettings {
	type?: 'disclaimer' | 'error' | 'info' | 'pending' | 'success';
	size?: 'default' | 'small' | 'medium' | 'large';
}

export interface AttentionCardProps {
	className?: string;
	icon?: Image;
	header?: string;
	text: string;
	settings: UmbracoSettings<AttentionCardSettings>;
}

export const AttentionCard: React.FC<AttentionCardProps> = ({
	className,
	text,
	icon = { properties: { altText: '' }, url: 'icons/icon-error.svg' },
	settings,
}) => {
	const { type = 'error', size = 'small' } = settings?.properties ?? {};
	return (
		<div
			role={type === 'error' ? 'alert' : null}
			className={classNames(
				styles.AttentionCard,
				styles[`AttentionCard___${type}`],
				styles[`AttentionCard___${size}`],
				className,
			)}
		>
			<div className={styles.AttentionCard_wrapper}>
				<div className={styles.AttentionCard_container}>
					{icon && (
						<Icon className={styles.AttentionCard_icon} size={type === 'disclaimer' ? 'lg' : '2xl'}>
							<Image
								className={styles.AttentionCard_iconImage}
								sizes="3rem"
								isCover={false}
								isCenter={true}
								url={icon.url}
								properties={icon.properties}
							/>
						</Icon>
					)}

					<RichText className={styles.AttentionCard_text} content={text} />
				</div>
			</div>
		</div>
	);
};
