import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';
import { getApiInstance } from 'Online/utilities/getApiInstance';

export const getDocuments = createAsyncThunk('documents/getDocuments', async (themeName: ThemeName, thunkAPI) => {
	try {
		const state = thunkAPI.getState() as RootState;
		const activeParty = state?.party?.partyObjects.find((obj) => obj.id === state.party.activeId);

		const documentsAPI = await getApiInstance(themeName, true).get(
			`/api/v1/document/GetByCustomerNumber?customerAltId=${activeParty?.idAlt}`,
		);
		return documentsAPI?.data;
	} catch (error) {
		if (error?.error?.response?.status) {
			const errorData = error?.error?.response?.data;
			return thunkAPI.rejectWithValue(errorData.ErrorCode ? errorData.ErrorCode : errorData);
		}
		return thunkAPI.rejectWithValue(error);
	}
});

export type documentsState = documentData & StatusHandling;

const initialState: documentsState = {
	documents: null,
	status: 'pending',
	error: null,
	tiaEnrichedDocuments: null,
};

export const documentsSlice = createSlice({
	name: 'Documents',
	initialState,
	reducers: {
		setTiaEnrichedDocuments: (state: documentsState, action) => {
			const tiaEnrichedDocuments = action.payload;
			state.tiaEnrichedDocuments = tiaEnrichedDocuments;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDocuments.fulfilled, (state, action) => {
				state.documents = action.payload;
				state.status = 'success';
			})
			.addCase(getDocuments.pending, (state) => {
				state.status = 'pending';
			})
			.addCase(getDocuments.rejected, (state, action) => {
				state.error = String(action.payload);
				state.status = 'error';
			});
	},
});

export const { setTiaEnrichedDocuments } = documentsSlice.actions;

export const selectDocuments = (state: RootState): documentsState => state.documents;
