import classNames from 'classnames';
import { Heading } from 'Shared';
import { CheckmarkListItem, CheckmarkListItemProps } from '../CheckmarkListItem';
import styles from './CheckmarkList.module.scss';

export interface CheckmarkList {
	heading: string;
	list: UmbracoContent<CheckmarkListItemProps>[];
	style: 'Light' | 'Dark';
}

export const CheckmarkList: React.FC<CheckmarkList> = ({ heading, list, style }: { heading; list; style }) => {
	return (
		<div className={classNames(styles.CheckmarkList, styles[`CheckmarkList___${style}`])}>
			<div className={styles.CheckmarkList_listContent}>
				<Heading headingLevel="h2" style="lg" className={styles.CheckmarkList_listHeading}>
					{heading}
				</Heading>

				<ul className={styles.CheckmarkList_list} role="list">
					{list.map((item, index) => {
						const { text, heading } = item?.content?.properties;

						return <CheckmarkListItem key={index} text={text} heading={heading} />;
					})}
				</ul>
			</div>
		</div>
	);
};
