import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import NextError from 'next/error';
import { Preview } from 'Online/components/4-habitats/Preview';
import { Authenticate } from 'Online/context/Authenticate';
import { Dictionary, DictionaryContext } from 'Online/context/Dictionary';
import { Page as PageContent, PageContext } from 'Online/context/PageContext';
import { Site, SiteContext } from 'Online/context/SiteContext';
import { ThemeContext } from 'Online/context/ThemeContext';
import { store } from 'Online/store/store';
import 'Online/styles/globals.scss';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const App: React.FC<AppProps & { err: NextError }> = ({ Component, pageProps, err }) => {
	const { content, root, culture, siteSettings, dictionary } = pageProps ?? {};
	const persistor = persistStore(store);
	const dictionaryProvider = new Dictionary(dictionary, culture);
	const site = new Site(root, culture, siteSettings);
	const pageContent: PageContent = new PageContent(content);

	return (
		<SessionProvider session={pageProps.session} refetchInterval={24 * 60 * 60}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<SiteContext.Provider value={site}>
						<DictionaryContext.Provider value={dictionaryProvider}>
							<PageContext.Provider value={pageContent}>
								<ThemeContext
									themeName={pageContent?.theme}
									documentType={pageContent?.pageType?.page?.documentType}
								>
									<Authenticate protected={pageContent?.authentication}>
										<Preview {...pageProps}>
											<Component {...pageProps} err={err} />
										</Preview>
									</Authenticate>
								</ThemeContext>
							</PageContext.Provider>
						</DictionaryContext.Provider>
					</SiteContext.Provider>
				</PersistGate>
			</Provider>
		</SessionProvider>
	);
};

export default App;
