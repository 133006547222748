import classNames from 'classnames';
import React from 'react';
import styles from './StepIndicator.module.scss';

import { ReactComponent as CheckmarkIcon } from 'Shared/public/icons/icon-checkmark.svg';
import { Heading, HeadingLevel } from 'Shared';

export interface StepIndicatorProps {
	className?: string;
	currentStepIndex: number;
	heading: string;
	headingLevel: HeadingLevel;
	steps: string[];
}

export const StepIndicator: React.FC<StepIndicatorProps> = ({
	className,
	currentStepIndex,
	heading,
	headingLevel,
	steps,
}) => {
	return (
		<div className={classNames(styles.StepIndicator, className)}>
			{heading && (
				<Heading headingLevel={headingLevel} className={styles.StepIndicator_heading}>
					{heading}
				</Heading>
			)}

			<ol className={styles.StepIndicator_list}>
				{steps &&
					steps.map((step, index) => (
						<li
							className={classNames(
								styles.StepIndicator_item,
								currentStepIndex > index && styles.StepIndicator_item___complete,
								currentStepIndex === index && styles.StepIndicator_item___current,
							)}
							key={index}
							aria-current={currentStepIndex === index ? 'step' : false}
						>
							<span className={styles.StepIndicator_itemCircle}>
								<CheckmarkIcon className={styles.StepIndicator_itemCheckMark} />
							</span>
							<p className={styles.StepIndicator_itemText}>{step}</p>
						</li>
					))}
			</ol>
		</div>
	);
};
