import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';
import { getApiInstance } from 'Online/utilities/getApiInstance';

export const getParty = createAsyncThunk('party/getParty', async (themeName: ThemeName, thunkAPI) => {
	try {
		const partyAPI = await getApiInstance(themeName, true).get('/api/v1/Party/Get');
		return partyAPI.data?.party;
	} catch (error) {
		if (error?.error?.response?.status) {
			const errorData = error?.error?.response?.data;
			return thunkAPI.rejectWithValue(errorData.ErrorCode ? errorData.ErrorCode : errorData);
		}
		return thunkAPI.rejectWithValue(error);
	}
});

export interface ActiveId {
	activeId: number | null;
}

export type PartyState = PartyData & StatusHandling & ActiveId;

const initialState: PartyState = {
	partyObjects: null,
	activeId: null,
	status: 'pending',
};

export const partySlice = createSlice({
	name: 'Personal details',
	initialState,
	reducers: {
		setActiveId: (state: PartyState, action) => {
			const activeId = action.payload;
			state.activeId = activeId;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getParty.fulfilled, (state, action) => {
				const { partyObjects } = action.payload;

				if (partyObjects.length === 1) {
					state.activeId = partyObjects[0].id;
				}

				state.partyObjects = partyObjects;
				state.status = 'success';
			})
			.addCase(getParty.pending, (state) => {
				state.status = 'pending';
			})
			.addCase(getParty.rejected, (state, action) => {
				state.error = String(action.payload);
				state.status = 'error';
			});
	},
});
export const { setActiveId } = partySlice.actions;

export const selectParty = (state: RootState): PartyState => state.party;
