import classNames from 'classnames';
import { ErrorMessage, FormfieldHelpCta } from 'Shared';
import styles from './FormfieldGroup.module.scss';

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
};

export interface FormfieldGroupProps {
	className?: string;
	id: string;
	name: string;
	label?: string;
	children: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	errorMessage?: string;
	state?: State;
	style?: 'inline' | 'inline-lg' | 'blocked' | 'blocked-no-label' | 'threeColumns';
	helpCtaText?: string;
	closeCtaText?: string;
}

const ariaError = (state: State, name: string, id: string) => {
	if (state?.['hasError']) return name + id;
};

export const FormfieldGroup: React.FC<FormfieldGroupProps> = ({
	className,
	id,
	name,
	label,
	register,
	errorMessage,
	state,
	style,
	children,
	helpCtaText,
	closeCtaText,
}) => {
	const { required, disabled, hasError } = state ?? {};
	return (
		<div
			className={classNames(
				styles.FormfieldGroup,
				styles[`FormfieldGroup___${style}`],
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<fieldset
				name={name}
				className={styles.FormfieldGroup_fieldset}
				{...(register && { ...register(name, { required }) })}
				aria-describedby={ariaError(state, name, id)}
			>
				{label && (
					<legend id={id} className={styles.FormfieldGroup_label}>
						{label}
						{helpCtaText && (
							<FormfieldHelpCta
								className={styles.FormfieldGroup_helpCta}
								closeText={closeCtaText}
								helpText={helpCtaText}
							/>
						)}
					</legend>
				)}
				{children}
				{hasError && <ErrorMessage id={name + id}>{errorMessage}</ErrorMessage>}
			</fieldset>
		</div>
	);
};
