import classNames from 'classnames';
import styles from './ContentCard.module.scss';
import { GridCell, LinkButton, Heading, Picture } from 'Shared';

type Orientation = 'Vertical' | 'Horizontal' | 'Horizontal Reverse' | '';

export interface ContentCardProps {
	className?: string;
	image?: Image;
	link?: {
		url: string;
		name: string;
	};
	kicker?: string;
	heading?: string;
	text?: string;
	settings?: {
		properties?: {
			desktopWidth?: GridCellWidth;
			mobileWidth?: GridCellWidth;
			orientation?: Orientation;
			largeImage?: boolean;
			showBackground?: boolean;
			displayLinkAsButton?: boolean;
		};
	};
}

export const ContentCard: React.FC<ContentCardProps> = ({
	className,
	image,
	link,
	kicker,
	heading,
	text,
	settings,
}) => {
	const { desktopWidth, mobileWidth, orientation, largeImage, showBackground, displayLinkAsButton } =
		settings?.['properties'] ?? {};

	const cardAsLink = !displayLinkAsButton && link;
	return (
		<GridCell desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
			{cardAsLink && (
				<a
					href={link?.url}
					className={classNames(
						styles.ContentCard___link,
						styles.ContentCard,
						showBackground && styles.ContentCard___background,
						{
							[styles.ContentCard___row]: orientation === 'Horizontal',
						},
						{
							[styles.ContentCard___rowReverse]: orientation === 'Horizontal Reverse',
						},
						className,
					)}
				>
					{image && (
						<div
							className={classNames(
								styles.ContentCard_media,
								largeImage && styles.ContentCard_media___large,
							)}
						>
							<span className={styles.ContentCard_mediabox}>
								<Picture
									aspectRatio={0.5625}
									url={image.url}
									properties={image.properties}
									focalPoint={image.focalPoint}
									sizes="100vw"
								/>
							</span>
						</div>
					)}
					<div className={styles.ContentCard_textbox}>
						<div className={styles.ContentCard_kicker}>{kicker}</div>
						<Heading headingLevel="h3" className={styles.ContentCard_heading}>
							{heading}
						</Heading>
						<div className={styles.ContentCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
					</div>
				</a>
			)}
			{!cardAsLink && (
				<div
					className={classNames(
						styles.ContentCard,
						showBackground && styles.ContentCard___background,
						{
							[styles.ContentCard___row]: orientation === 'Horizontal',
						},
						{
							[styles.ContentCard___rowReverse]: orientation === 'Horizontal Reverse',
						},
						className,
					)}
				>
					{image && (
						<div
							className={classNames(
								styles.ContentCard_media,
								largeImage && styles.ContentCard_media___large,
							)}
						>
							<span className={styles.ContentCard_mediabox}>
								<Picture
									aspectRatio={0.5625}
									url={image.url}
									properties={image.properties}
									focalPoint={image.focalPoint}
									sizes="100vw"
								/>
							</span>
						</div>
					)}
					<div className={styles.ContentCard_textbox}>
						<div className={styles.ContentCard_kicker}>{kicker}</div>
						<Heading headingLevel="h3" className={styles.ContentCard_heading}>
							{heading}
						</Heading>
						<div className={styles.ContentCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
						{link && (
							<div className={styles.ContentCard_cta}>
								<LinkButton url={link.url} style="secondary" size="small">
									{link.name}
								</LinkButton>
							</div>
						)}
					</div>
				</div>
			)}
		</GridCell>
	);
};
