import classNames from 'classnames';
import { Image, Link } from 'Shared';
import styles from './FooterNavigation.module.scss';

export interface FooterNavigationProps {
	className?: string;
	footerNavigationItems?: Link[];
	socialMediaLinks?: {
		content: {
			properties: {
				heading: string;
				link: Link;
				logo: Image;
			};
		};
	}[];
	globalElements?: {
		chatIcon?: Image;
	};
	dictionaryContext?: (valueKey: string, defaultValue: string) => string;
	displayChat?: boolean;
}

export const FooterNavigation: React.FC<FooterNavigationProps> = ({
	className,
	footerNavigationItems,
	socialMediaLinks,
	globalElements,
	dictionaryContext,
	displayChat,
}) => {
	return (
		<nav
			className={classNames(styles.FooterNavigation, className)}
			aria-labelledby="primary-footerNavigation-heading"
		>
			<ul className={classNames(styles.FooterNavigation_list)}>
				{footerNavigationItems &&
					footerNavigationItems.map((footerNavigationItem: Link, index) => (
						<li key={index} className={classNames(styles.FooterNavigation_item)}>
							<Link className={classNames(styles.FooterNavigation_link)} url={footerNavigationItem.url}>
								{footerNavigationItem.name}
							</Link>
						</li>
					))}
			</ul>

			<ul className={styles.FooterNavigation_socialMediaLinks}>
				{displayChat && (
					<li className={classNames(styles.FooterNavigation_socialMediaItem)}>
						<button className={styles.FooterNavigation_socialMediaLink} onClick={() => console.log('chat')}>
							{globalElements.chatIcon && (
								<Image
									className={styles.FooterNavigation_socialMediaIcon}
									url={globalElements?.chatIcon.url}
									properties={globalElements?.chatIcon.properties}
									sizes="3rem"
								/>
							)}
							<span> {dictionaryContext('Services.Chat', 'chat')} </span>
						</button>
					</li>
				)}

				{socialMediaLinks &&
					socialMediaLinks.map((socialLinkItem, index) => {
						const { heading, link, logo } = socialLinkItem?.content?.properties ?? {};
						return (
							<li key={index} className={styles.FooterNavigation_socialMediaItem}>
								<a
									className={styles.FooterNavigation_socialMediaLink}
									href={link?.url}
									target={link?.target}
								>
									{logo && (
										<Image
											className={styles.FooterNavigation_socialMediaIcon}
											url={logo?.url}
											properties={logo.properties}
											sizes="3rem"
										/>
									)}

									{heading}
								</a>
							</li>
						);
					})}
			</ul>
		</nav>
	);
};
