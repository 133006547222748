import classNames from 'classnames';
import styles from './PageLoader.module.scss';
import React from 'react';
import { Spinner, Heading, SpinnerProps } from 'Shared';

export interface PageLoaderProps {
	className?: string;
	title?: string;
	type?: SpinnerProps['type'];
	maxHeight?: string;
}

export const PageLoader: React.FC<PageLoaderProps> = ({
	className,
	title,
	type = 'dottedCircle',
	maxHeight = '70rem',
}) => {
	return (
		<div className={classNames(styles.PageLoader, className)} style={{ maxHeight: maxHeight }}>
			<Spinner type={type} style="large" color="primary" />
			<Heading headingLevel="h1" className={styles.PageLoader_title}>
				{title}
			</Heading>
		</div>
	);
};
