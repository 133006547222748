import styles from './BlurbCard.module.scss';
import { Heading, Link, GridCell, Picture, PictureProps } from 'Shared';
import classNames from 'classnames';

export interface BlurbCardProps {
	className?: string;
	title: string;
	link: Link;
	content?: string;
	image?: PictureProps;
	settings?: {
		properties?: {
			desktopWidth: GridCellWidth;
			mobileWidth: GridCellWidth;
		};
	};
}

export const BlurbCard: React.FC<BlurbCardProps> = ({ className, content, title, link, image, settings }) => {
	const { desktopWidth, mobileWidth } = settings?.properties ?? {};

	return (
		<GridCell
			className={classNames(styles.BlurbCard, className)}
			desktopWidth={desktopWidth}
			mobileWidth={mobileWidth}
		>
			{image && (
				<div className={styles.BlurbCard_image}>
					<Picture url={image.url} properties={image.properties} sizes={image.sizes} />
				</div>
			)}

			<div className={styles.BlurbCard_content}>
				<div>
					<Heading className={styles.BlurbCard_title} headingLevel={'h2'}>
						{title}
					</Heading>
					<p className={styles.BlurbCard_body}>{content}</p>
				</div>

				<Link className={styles.BlurbCard_link} style="dash" url={link.url}>
					{link.name}
				</Link>
			</div>
		</GridCell>
	);
};
