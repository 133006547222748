import React from 'react';
import ReactPlayer from 'react-player/lazy';
import styles from './Player.module.scss';

export interface PlayerProps {
	url: string;
	isLight?: boolean;
	poster?: string;
	playing?: boolean;
	loop?: boolean;
	controls?: boolean;
}

export const Player: React.FC<PlayerProps> = ({
	url,
	isLight = true,
	poster,
	playing = false,
	loop = false,
	controls = true,
}) => {
	const light = !isLight ? false : poster ? poster : true;

	return (
		<ReactPlayer
			url={url}
			className={styles.Player}
			light={light}
			playing={playing}
			loop={loop}
			controls={controls}
			width="100%"
			height="100%"
			config={{
				youtube: {
					playerVars: { modestbranding: 1 },
				},
			}}
		/>
	);
};
