import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';

export interface UpsellCoverageState {
	coverages: Coverage[];
}

const initialState: UpsellCoverageState = {
	coverages: [],
};

const getCoverageById = (state: UpsellCoverageState, coverageId: string) => {
	return state.coverages
		.map((coverage) => {
			return coverage.id;
		})
		.indexOf(coverageId);
};

export const upsellCoverageSlice = createSlice({
	name: 'upsellCoverage',
	initialState,
	reducers: {
		addCoverage: (state, action: PayloadAction<Coverage>) => {
			const coverage = { ...action.payload };
			coverage.active = true;
			state.coverages.push(coverage);
		},
		removeCoverage: (state, action: PayloadAction<Coverage>) => {
			const coverageIndex = state.coverages.findIndex((c) => {
				c.id === action.payload.id;
			});
			state.coverages.splice(coverageIndex, 1);
		},
	},
});

export const { addCoverage, removeCoverage } = upsellCoverageSlice.actions;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectUpsellCoverages = (state: RootState) => state.upsellCoverages.coverages;

export const selectUpsellCoverage = (id: string, state: RootState): Coverage => {
	const coverage = getCoverageById(state.upsellCoverages, id);
	return state.upsellCoverages.coverages[coverage];
};
