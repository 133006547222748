import classNames from 'classnames';
import { Heading, Link } from 'Shared';
import styles from './Navigation.module.scss';

export interface NavigationProps {
	children?: React.ReactNode;
	className?: string;
	heading?: string;
	navigationItems?: NavigationItem[];
	externalNavigationItems?: Link[];
}

export const Navigation: React.FC<NavigationProps> = ({
	children,
	className,
	heading,
	navigationItems,
	externalNavigationItems,
}) => {
	return (
		<>
			{externalNavigationItems?.length > 0 ? (
				<nav className={classNames(styles.Navigation___external, className)}>
					<ul className={classNames(styles.Navigation_list, styles.Navigation_list___external)}>
						{externalNavigationItems.map((externalNavItem, index) => (
							<li className={classNames(styles.Navigation_item)} key={index}>
								{externalNavItem?.url ? (
									<Link
										className={classNames(
											styles.Navigation_link,
											index === 0 && styles.Navigation_link___current,
										)}
										url={externalNavItem.url}
									>
										{externalNavItem?.name}
									</Link>
								) : (
									<span className={styles.Navigation_link}>{externalNavItem?.name}</span>
								)}
							</li>
						))}
					</ul>
				</nav>
			) : null}
			<nav
				className={classNames(styles.Navigation, className)}
				role="navigation"
				aria-labelledby="primary-navigation-heading"
			>
				<Heading headingLevel="h6" id="primary-navigation-heading" className={styles.Navigation_heading}>
					{heading}
				</Heading>

				{navigationItems?.length > 0 ? (
					<ul className={classNames(styles.Navigation_list)}>
						{navigationItems.map((navigationItem: NavigationItem, index) => (
							<li key={index} className={classNames(styles.Navigation_item)}>
								<Link
									className={classNames(styles.Navigation_link, {
										[styles.Navigation_link___active]: navigationItem.state === 'isActive',
									})}
									url={navigationItem.url}
								>
									{navigationItem.title}
								</Link>
							</li>
						))}
					</ul>
				) : null}
				{children}
			</nav>
		</>
	);
};
