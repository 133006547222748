/* eslint-disable react-hooks/rules-of-hooks */
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { DictionaryContext } from 'Online/context/Dictionary';
import { _Status } from 'Online/dictionaryItems';
import { DESTROY_SESSION } from 'Online/store/actionTypeConstants';
import { useAppDispatch } from 'Online/store/hooks';
import { useContext, useEffect, useState } from 'react';
import { PageLoader } from 'Shared';
import { getCookieByName } from 'Shared/utilities/getCookieByName';
import { IdleLogOut } from './IdleLogOut';
export interface Authenticate {
	protected: boolean;
	children?: React.ReactNode;
}

export const Authenticate: React.FC<Authenticate> = (props) => {
	if (!props.protected) return <>{props.children}</>;

	const dispatch = useAppDispatch();
	const router = useRouter();
	const [isExpired, setIsExpired] = useState(false);
	const dictionary = useContext(DictionaryContext);
	const expirationDate = Number(getCookieByName('X-Expiration-Date'));
	const { data: session, status } = useSession();

	useEffect(() => {
		if (props?.protected && status === 'unauthenticated') dispatch({ type: DESTROY_SESSION });
	}, [status, dispatch, props?.protected]);

	if (props.protected) {
		if (isExpired || (expirationDate && expirationDate < Date.now())) {
			router?.push('/logud');
		} else if ((!session && status === 'unauthenticated') || !expirationDate) {
			signIn('auth0');
		}

		if (session && status === 'authenticated' && expirationDate && !isExpired) {
			return <IdleLogOut isExpiredCallback={() => setIsExpired(true)}>{props.children}</IdleLogOut>;
		} else {
			return <PageLoader title={_Status(dictionary, 'Loading')} />;
		}
	}
};
