import classNames from 'classnames';
import { ErrorMessage, FormfieldHelpCta, Label } from 'Shared';
import styles from './FormfieldString.module.scss';

type State = {
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	isHidden?: boolean;
};

export interface FormfieldStringProps {
	className?: string;
	closeCtaText?: string;
	defaultValue?: string | number;
	id: string;
	helpCtaText?: string;
	label: string;
	name?: string;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	errorMessage?: string;
	state?: State;
	type: 'text' | 'email' | 'search' | 'number' | 'password' | 'tel' | 'url' | 'date';
	min?: number | string;
	max?: number | string;
	pattern?: string;
	maxLength?: number;
	minLength?: number;
}

const ariaLabel = (state: State, label: string) => {
	if (state?.['isHidden']) return label;
};

const ariaError = (state: State, type: string, id: string) => {
	if (state?.['hasError']) return type + id;
};

export const FormfieldString: React.FC<FormfieldStringProps> = ({
	className,
	closeCtaText,
	state,
	id,
	helpCtaText,
	label,
	type,
	register,
	placeholder,
	name,
	defaultValue,
	min,
	minLength,
	max,
	maxLength,
	pattern,
	errorMessage,
}) => {
	const { required, disabled, hasError, isHidden } = state ?? {};
	return (
		<div
			className={classNames(
				styles.FormfieldString,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			{!isHidden && (
				<div className={styles.FormfieldString_label}>
					<Label id={id} className={styles.FormfieldString_labelText} state={state}>
						{label}
					</Label>
					{helpCtaText && <FormfieldHelpCta closeText={closeCtaText} helpText={helpCtaText} />}
				</div>
			)}

			<input
				type={type}
				className={classNames(styles.FormfieldString_input, styles.Input___string, {
					[styles.hasError]: hasError,
				})}
				name={name}
				id={id}
				disabled={disabled}
				placeholder={placeholder}
				defaultValue={defaultValue}
				aria-label={ariaLabel(state, label)}
				aria-describedby={ariaError(state, type, id)}
				min={min}
				max={max}
				maxLength={maxLength}
				minLength={minLength}
				pattern={pattern}
				{...(register && { ...register(name, { required }) })}
			/>
			{hasError && <ErrorMessage id={type + id}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
