import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'Online/store/store';
import { getApiInstance } from 'Online/utilities/getApiInstance';

export const getPersonalPoliciesById = createAsyncThunk(
	'personalPoliciesById/getPersonalPoliciesById',
	async (themeName: ThemeName, thunkAPI) => {
		try {
			const state = thunkAPI.getState() as RootState;
			const activeId = state.party.activeId;

			const policyAPI = await getApiInstance(themeName, true).get(
				'/api/v1/Policy/GetPortfolioById?customerId=' + activeId,
			);
			return policyAPI.data;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export type PoliciesState = PolicyData & StatusHandling;
const initialState: PoliciesState = {
	portfolio: null,
	quotesCount: 0,
	customerStatus: { isPlusCustomer: false },
	annualTotalPrice: null,
	status: 'pending',
};

export const policiesSlice = createSlice({
	name: 'Portfolio policies',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getPersonalPoliciesById.fulfilled, (state, action) => {
				const { policyPortfolio } = action.payload;
				const {
					policies,
					quotes,
					totalPrice,
					customerStatus: { isPlusCustomer },
				} = policyPortfolio;

				state.portfolio = policies;
				state.quotesCount = quotes;
				state.annualTotalPrice = totalPrice;
				state.customerStatus.isPlusCustomer = isPlusCustomer;
				state.status = 'success';
			})
			.addCase(getPersonalPoliciesById.pending, (state) => {
				state.status = 'pending';
			})
			.addCase(getPersonalPoliciesById.rejected, (state, action) => {
				state.error = String(action.payload);
				state.status = 'error';
			});
	},
});

export const selectPolicies = (state: RootState): PoliciesState => state.policies;
