import classNames from 'classnames';
import { BlurbCard, Grid, Heading } from 'Shared';
import styles from './ArticleList.module.scss';

export interface ArticleListItem {
	id: string;
	url: string;
	properties: {
		image: Image;
		heading: string;
		teaser: string;
		title: string;
		articleListTitle?: string;
		settings: {
			properties: {
				desktopWidth: string;
				mobileWidth: string;
			};
		};
	};
}
export interface ArticleListProps {
	heading?: string;
	articles: ArticleListItem[];
}

export const ArticleList: React.FC<ArticleListProps> = ({ articles, heading, children }) => {
	return (
		<div className={classNames(styles.ArticleList, heading && styles.ArticleList___includesHeading)}>
			{heading && (
				<Heading headingLevel="h2" style="md" className={styles.ArticleList_heading}>
					{heading}
				</Heading>
			)}
			<Grid nogutter={true} wrap={true} className="u-grid--cell-padding30-sm u-grid--cell-padding20-md">
				{articles &&
					articles.map((result, index) => {
						const picture = {
							...result.properties?.image,
							sizes: '(max-width:64rem) 100vw, (max-width:80rem) 50vw, 33vw',
						};
						return (
							<BlurbCard
								key={index}
								title={
									result?.properties?.articleListTitle
										? result?.properties?.articleListTitle
										: result?.properties?.heading
								}
								content={result?.properties?.teaser}
								image={picture}
								link={{ url: result?.url, name: result?.properties?.title }}
								settings={{
									properties: {
										desktopWidth: '50',
										mobileWidth: null,
									},
								}}
								className="u-grid__cell--width-50@sm u-grid__cell--width-33@lg"
							/>
						);
					})}
			</Grid>
			{children}
		</div>
	);
};
