import { GridCell, Heading, Picture } from 'Shared';
import styles from './LinkCard.module.scss';

export interface LinkCardProps {
	className?: string;
	link: Link;
	image: Image;
	desktopWidth?: GridCellWidth;
	mobileWidth?: GridCellWidth;
}

export const LinkCard: React.FC<LinkCardProps> = ({ className, link, image, desktopWidth, mobileWidth }) => {
	return (
		<GridCell className={className} desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
			{link && (
				<a href={link?.url} className={styles.LinkCard}>
					<span className={styles.LinkCard_background} />
					<Heading headingLevel={'h3'} className={styles.LinkCard_heading}>
						{link?.name}
					</Heading>
					{image && (
						<div className={styles.LinkCard_media}>
							<span className={styles.LinkCard_mediabox}>
								<Picture
									aspectRatio={0.5625}
									url={image.url}
									properties={image.properties}
									focalPoint={image.focalPoint}
									sizes="100vw"
								/>
							</span>
						</div>
					)}
				</a>
			)}
		</GridCell>
	);
};
