import classNames from 'classnames';
import { Container, Heading, RichText } from 'Shared';
import styles from './Factbox.module.scss';
import { FactboxItem, FactboxItemProps } from './FactboxItem';

export interface FactboxProps {
	factboxItems?: FactboxItemProps[];
	kicker?: string;
	text?: string;
	title?: string;
	style?: 'vanilla';
}

export const Factbox: React.FC<FactboxProps> = ({ factboxItems, kicker, text, title, style }) => (
	<div className={classNames(styles.Factbox, styles[`Factbox___${style}`])}>
		<Container width="Small" className={styles.Factbox_container}>
			<div className={styles.Factbox_wrapper}>
				{kicker && <p className={styles.Factbox_kicker}>{kicker}</p>}
				{title && (
					<Heading className={styles.Factbox_title} headingLevel="h2" style="md">
						{title}
					</Heading>
				)}
				{text && <RichText className={styles.Factbox_text} content={text} style="center" />}
				{factboxItems &&
					factboxItems.map((item, index) => {
						return <FactboxItem className={styles.Factbox_item} {...item} key={index} style={style} />;
					})}
			</div>
		</Container>
	</div>
);
