import { Button } from 'Shared';
import { FormfieldCheckbox, Form, FormfieldString } from 'Shared';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
	email: string;
};

export interface NewsletterProps {
	emailPlaceholder?: string;
	emailLabel: string;
	signupLabel: string;
	newsletterTypes?: string[];
}

export const Newsletter: React.FC<NewsletterProps> = ({
	emailLabel,
	emailPlaceholder,
	signupLabel,
	newsletterTypes,
}) => {
	const { register, handleSubmit } = useForm<FormData>();
	const onSubmit = (data) => {
		alert(`Thank for submitting, ${JSON.stringify(data)}`);
	};
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<FormfieldString
				id="email"
				name="email"
				label={emailLabel}
				type="email"
				register={register}
				placeholder={emailPlaceholder}
				className="u-bottom-margin--md"
			/>
			{newsletterTypes ? renderNewsletterTypes(newsletterTypes) : null}
			<Button type="submit" style="primary">
				{signupLabel}
			</Button>
		</Form>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderNewsletterTypes(newsletterTypes: string[]): ReactElement<any, string> {
	return (
		<div className="u-bottom-margin--xl">
			{newsletterTypes.map((renderNewsletterType, index) => {
				return (
					<FormfieldCheckbox
						id={renderNewsletterType}
						key={index}
						name={renderNewsletterType}
						label={renderNewsletterType}
					/>
				);
			})}
		</div>
	);
}
